import React from "react";

export default ({ width, ...props }) => (
  <svg
    width={width}
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M48 24.7349C48 31.1001 45.4714 37.2046 40.9706 41.7054C36.4697 46.2063 30.3652 48.7349 24 48.7349C17.6348 48.7349 11.5303 46.2063 7.02944 41.7054C2.52856 37.2046 0 31.1001 0 24.7349C0 18.3697 2.52856 12.2652 7.02944 7.7643C11.5303 3.26343 17.6348 0.734863 24 0.734863C30.3652 0.734863 36.4697 3.26343 40.9706 7.7643C45.4714 12.2652 48 18.3697 48 24.7349ZM19.71 18.8339H15.75C15.66 13.1759 20.04 11.2349 24.018 11.2349C28.209 11.2349 32.037 13.4249 32.037 17.9549C32.037 21.1949 30.132 22.7369 28.305 24.1259C26.094 25.8029 25.275 26.4299 25.275 28.5839V29.6489H21.351L21.33 28.2599C21.216 25.4789 22.815 23.7659 24.834 22.2989C26.604 20.9669 27.729 20.0909 27.729 18.1859C27.729 15.7109 25.845 14.6819 23.787 14.6819C21.084 14.6819 19.713 16.4909 19.713 18.8339H19.71ZM23.463 38.1629C21.711 38.1629 20.436 36.9809 20.436 35.3819C20.436 33.7259 21.711 32.5619 23.466 32.5619C25.293 32.5619 26.55 33.7259 26.55 35.3819C26.55 36.9809 25.29 38.1629 23.463 38.1629Z"
      fill="#FA7800"
    />
  </svg>
);
