import React from "react";

export default ({ width, ...props }) => (
  <svg
    width={width}
    viewBox="0 0 49 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.5715 22.3347H16.9049V27.1347H11.5715V22.3347ZM11.5715 31.9347H16.9049V36.7347H11.5715V31.9347ZM22.2382 22.3347H27.5715V27.1347H22.2382V22.3347ZM22.2382 31.9347H27.5715V36.7347H22.2382V31.9347ZM32.9049 22.3347H38.2382V27.1347H32.9049V22.3347ZM32.9049 31.9347H38.2382V36.7347H32.9049V31.9347Z"
      fill="#A00078"
    />
    <path
      d="M6.23812 48.7349H43.5715C46.5128 48.7349 48.9048 46.5821 48.9048 43.9349V10.3349C48.9048 7.68766 46.5128 5.53486 43.5715 5.53486H38.2381V0.734863H32.9048V5.53486H16.9048V0.734863H11.5715V5.53486H6.23812C3.29679 5.53486 0.904785 7.68766 0.904785 10.3349V43.9349C0.904785 46.5821 3.29679 48.7349 6.23812 48.7349ZM43.5715 15.1349L43.5741 43.9349H6.23812V15.1349H43.5715Z"
      fill="#A00078"
    />
  </svg>
);
