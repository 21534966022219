import React from "react";

export default ({ width, ...props }) => (
  <svg
    width={width}
    viewBox="0 0 49 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.5664 0.734863C11.3664 0.734863 0.566406 11.5349 0.566406 24.7349C0.566406 37.9349 11.3664 48.7349 24.5664 48.7349C37.7664 48.7349 48.5664 37.9349 48.5664 24.7349C48.5664 11.5349 37.7664 0.734863 24.5664 0.734863ZM22.6807 11.0206H26.4521V29.8777H22.6807V11.0206ZM24.5664 40.1634C23.195 40.1634 21.995 38.9634 21.995 37.592C21.995 36.2206 23.195 35.0206 24.5664 35.0206C25.9378 35.0206 27.1378 36.2206 27.1378 37.592C27.1378 38.9634 25.9378 40.1634 24.5664 40.1634Z"
      fill="#78C846"
    />
  </svg>
);
