import React from "react";

export default ({ width, ...props }) => (
  <svg
    width={width}
    viewBox="0 0 49 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.2628 0.734863L15.2148 6.5861H7.46282V11.5448L2.59082 14.7431C1.19882 15.6109 0.262817 17.1729 0.262817 18.9828V43.7762C0.262817 45.0913 0.76853 46.3526 1.6687 47.2825C2.56888 48.2124 3.78978 48.7349 5.06282 48.7349H43.4628C46.1268 48.7349 48.2628 46.5282 48.2628 43.7762V18.9828C48.2628 17.1729 47.3268 15.6109 45.9348 14.7431L41.0628 11.5448V6.5861H33.3108L24.2628 0.734863ZM12.2628 11.5448H36.2628V23.644L24.2628 31.3795L12.2628 23.644V11.5448ZM14.6628 14.0241V17.7431H33.8628V14.0241H14.6628ZM7.46282 17.4456V20.5448L5.06282 18.9828L7.46282 17.4456ZM41.0628 17.4456L43.4628 18.9828L41.0628 20.5448V17.4456ZM14.6628 20.2225V23.9415H33.8628V20.2225H14.6628Z"
      fill="#FAC800"
    />
  </svg>
);
