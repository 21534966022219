import React from "react";

export default ({ width, ...props }) => (
  <svg
    width={width}
    viewBox="0 0 49 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.3926 0.734863C11.1377 0.734863 0.392578 11.48 0.392578 24.7349C0.392578 37.9897 11.1377 48.7349 24.3926 48.7349C37.6474 48.7349 48.3926 37.9897 48.3926 24.7349C48.3926 11.48 37.6474 0.734863 24.3926 0.734863ZM23.6397 8.97022C24.7394 8.97026 25.6349 9.26682 26.3262 9.86378C27.0174 10.4608 27.3633 11.2481 27.3633 12.2222C27.3633 13.1963 27.0175 13.9807 26.3262 14.5777C25.6349 15.1747 24.7394 15.4742 23.6397 15.4741C22.5399 15.4742 21.6444 15.1747 20.9531 14.5777C20.2933 13.9807 19.9629 13.1963 19.9629 12.2222C19.9629 11.2481 20.2933 10.4608 20.9531 9.86378C21.6444 9.26678 22.5399 8.97026 23.6397 8.97022ZM27.0791 19.479L28.0225 20.563C27.5197 21.8827 27.2666 23.3434 27.2666 24.9458V36.4947C27.2666 36.9031 27.3005 37.1842 27.3633 37.3413C27.4575 37.4985 27.6614 37.6559 27.9756 37.813C28.5412 38.1587 29.3719 38.489 30.4717 38.8033L30.1436 40.4995C27.4413 40.2167 25.5392 40.0777 24.4395 40.0777C23.3397 40.0777 21.456 40.2167 18.7852 40.4995L18.4073 38.8033C19.5384 38.5519 20.3721 38.237 20.9063 37.8599C21.3776 37.5771 21.6123 37.1231 21.6123 36.4947V24.7115C21.6123 23.6117 21.1863 23.0621 20.3379 23.0621L18.6885 23.3902L18.3135 21.5064C19.4447 21.0351 20.9362 20.5781 22.7901 20.1382C24.6439 19.6983 26.0736 19.479 27.0791 19.479Z"
      fill="#333333"
    />
  </svg>
);
