import React from "react";

export default ({ width, ...props }) => (
  <svg
    width={width}
    viewBox="0 0 49 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.80966 43.9349V29.5349C3.80966 24.4427 6.01032 19.5591 9.92753 15.9584C13.8447 12.3577 19.1576 10.3349 24.6974 10.3349C30.2372 10.3349 35.55 12.3577 39.4672 15.9584C43.3845 19.5591 45.5851 24.4427 45.5851 29.5349V43.9349H48.1961V48.7349H1.19869V43.9349H3.80966ZM9.03159 29.5349H14.2535C14.2535 26.9888 15.3539 24.547 17.3125 22.7466C19.2711 20.9463 21.9275 19.9349 24.6974 19.9349V15.1349C20.5426 15.1349 16.5579 16.652 13.62 19.3525C10.6821 22.0531 9.03159 25.7157 9.03159 29.5349ZM22.0864 0.734863H27.3084V7.93486H22.0864V0.734863ZM45.0055 7.47406L48.6974 10.8677L43.1621 15.9581L39.4676 12.5645L45.0055 7.47406ZM0.697388 10.8677L4.38929 7.47406L9.92715 12.5621L6.23786 15.9605L0.697388 10.8677Z"
      fill="#F10C4A"
    />
  </svg>
);
