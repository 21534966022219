import React from "react";

export default ({ width, ...props }) => (
  <svg
    width={width}
    viewBox="0 0 49 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.00075 48.7349C4.27203 48.7315 3.57439 48.4392 3.06082 47.9222C2.54724 47.4053 2.25963 46.7057 2.26105 45.977C2.26743 45.7 2.31067 45.4251 2.38963 45.1595L4.36324 38.0206C4.44574 37.757 4.35252 37.4913 4.23145 37.2813L4.20788 37.2384C4.19931 37.2245 4.15859 37.1677 4.12966 37.1259C4.10073 37.0841 4.06752 37.0392 4.03752 36.9952L4.01716 36.9663C1.52513 33.1893 0.196962 28.7638 0.197441 24.2388C0.178155 18.0684 2.59213 12.2388 6.99686 7.81594C11.5548 3.24951 17.6514 0.734866 24.1786 0.734866C29.7159 0.73213 35.0889 2.61556 39.4125 6.07487C43.6394 9.47665 46.5773 14.2177 47.6852 19.4334C48.0262 21.0179 48.1979 22.6341 48.1973 24.2549C48.1973 30.5688 45.7716 36.4992 41.3658 40.9552C36.9214 45.4552 30.9534 47.9259 24.5622 47.9259C22.3122 47.9259 19.4332 47.3517 18.051 46.9617C16.3881 46.4967 14.812 45.8902 14.6384 45.8259C14.4547 45.7556 14.2598 45.7193 14.0631 45.7188C13.8497 45.7175 13.6383 45.7594 13.4416 45.842L13.3506 45.8774L6.12042 48.4895C5.76531 48.639 5.38583 48.7221 5.00075 48.7349Z"
      fill="#0096C8"
    />
  </svg>
);
